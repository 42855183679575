import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { animated } from 'react-spring'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Item = styled(animated.div)`
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      padding-top: 50%;
    }
  }
`

const Overlay = styled.div`
  background-color: ${props => props.theme.brand.primary};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
  opacity: 0;
  transition: all .25s;
`

const Client = styled.h2`
  font-size:14px;
`

const Title = styled.div`
  font-size:14px;
`

const Content = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  a {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    height: 100%;
    left: 0;
    padding: 1rem;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    transition: all 0.125s ease-in-out;
    text-decoration: none;
    
    h2 {
      color: #fff;
      font-size: 1.5rem;
      margin: 0 0 .5rem;
    }

    ${Client},
    ${Title} {
      opacity:0;
      transition: all .25s;
    }

    &:hover {
      ${Overlay},
      ${Client},
      ${Title} {
        opacity:1;
      }
    }
  }
`

const ImageWrapper = styled.div`
  > div {
    height: 100%;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%;

    > div {
      position: static !important;
    }
  }
`

const ProjectItem = ({ node, style, testid }) => (
  <Item key={node.fields.slug} style={style} data-testid={testid}>
    <Content>
      <ImageWrapper>
        <Img fluid={node.frontmatter.cover.childImageSharp.fluid} />
      </ImageWrapper>
      <Link to={node.fields.slug}>
        <Overlay style={{ backgroundColor: node.frontmatter.color }} />
        <Client>{node.frontmatter.client}</Client>
        <Title>{node.frontmatter.title}</Title>
      </Link>
    </Content>
  </Item>
)

export default ProjectItem

ProjectItem.propTypes = {
  node: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  testid: PropTypes.string.isRequired,
}
