module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'Michael Steeger', // Navigation and Site Title
  siteTitleAlt: 'Michael Steeger - Freelance Art Director und Creative Director', // Alternative Site title for SEO
  siteTitleShort: 'MichaelSteeger', // short_name for manifest
  siteUrl: 'https://michael-steeger.de', // Domain of your site. No trailing slash!
  siteLanguage: 'de', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Online Portfolio of Michael Steeger. A Freelance Art Director und Creative Director',
  author: 'Michael Steeger', // Author for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  //userTwitter: '@emma', // Twitter Username
  //ogSiteName: 'emma', // Facebook Site Name
  //ogLanguage: 'en_US',
  //googleAnalyticsID: 'UA-12345689-1',

  // Manifest and Progress color
  themeColor: '#ff9e30',
  backgroundColor: '#fff',
}
