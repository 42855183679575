import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import config from "../../config/website";

const Wrapper = styled.header`
  align-items: center;
  display: flex;
  padding: 1rem 0;
  position: relative;
  z-index: 1000;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 1rem 0 1rem 0;
    flex-wrap: wrap;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0 ${(props) => props.theme.spacer.horizontal};
  a {
    font-size: 18px;
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    display: inline-block
    z-index: 100;
    &:hover {
      color: ${(props) => props.theme.brand.primary};
    }
  }
  .active {
    color: ${(props) => props.theme.brand.primary};
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    order: 2;
    margin-top: 0.5rem;
    justify-content: flex-start;
  }
`;

const Name = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  padding-left: 1rem;
  a {
    font-size: 1.25rem;
    color: #000;
    &:hover,
    &:focus {
      color: ${(props) => props.theme.colors.body_color};
      text-decoration: none;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    order: 1;
    flex: 0 0 auto;
    width: 100%;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0 ${(props) => props.theme.spacer.horizontal};
  a {
    font-size: 1.25rem;
    line-height: 20px;
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.s}) {
    padding: 0 1rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    order: 3;
  }
`;

const Navigation = () => (
  <Wrapper data-testid='navigation'>
    <Name>
      <Link to='/' data-testid='home-title-link'>
        {config.siteTitle}
      </Link>
    </Name>
    <Nav>
      <Link activeClassName='active' to='/services'>
        Leistungen
      </Link>
      <Link activeClassName='active' to='/about'>
        Profil
      </Link>
      <Link activeClassName='active' to='/contact'>
        Kontakt
      </Link>
    </Nav>
  </Wrapper>
);

export default Navigation;
