import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';

const Wrapper = styled.footer`
  margin: 6rem 0 1rem 0;
  padding: 1rem ${props => props.theme.spacer.horizontal};
  text-align: center;
  color: ${props => props.theme.colors.grey};
  a {
    text-decoration: none;
    color: ${props => props.theme.brand.primary};
  }
`

const Footer = () => (
  <Wrapper data-testid="footer">
    Michael Steeger 2019 &nbsp;
    <Link to="/impressum">Impressum</Link>
  </Wrapper>
)

export default Footer
