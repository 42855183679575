import { darken } from 'polished'

const brand = {
  primary: '#ff9e30',
  secondary: '#ff9e30',
}

const colors = {
  grey: '#6b6b6b',
  black: '#333',
  white: '#fefefe',
  bg_color: '#f7f7f7',
  body_color: 'rgba(0,0,0,.6)',
  link_color: brand.primary,
  link_color_hover: `${darken(0.15, brand.primary)}`,
}

const theme = {
  brand,
  colors,
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
  container: {
    base: '100rem',
    text: '55rem',
  },
  spacer: {
    horizontal: '1rem',
    vertical: '3rem',
  },
}

export default theme
